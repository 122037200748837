import { createGlobalStyle } from "styled-components";

export class ClassName {
  static shadow01 = "shadow-01";
}

export const GlobalStyle = createGlobalStyle`
    body {
      margin:0;
      color:#333333;
      line-height:1.2;    
      min-width:360px;
    }

    body::-webkit-scrollbar{
      display:none;
    }

    button, img {
      cursor:pointer;
    }

    body, input, button, textarea, select, option {
        font-family:Noto Sans Kr;
    }

  .shadow-01 {
     box-shadow:0px 3px 6px #00000029;
  }

  :root {
    --main-color:#ffbd97;
    --main-hover-color:#d79e7d;
    --sub-color:#ffbd97;
  }
`;
